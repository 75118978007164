import { BookingState } from './BookingParticipantsList';

export interface IBulkBookingModel {
  trekId: number;
  batchId: number;
  bookingState: BookingState.COMPLETED | BookingState.PAYMENT;
}

export interface IBulkBookingResponse {
  bookingSuccessful: boolean;
  bookingId: number;
  message: string;
  primaryUserId: number;
  bookingCreationFailedRows: RowDetailsRepresentation[];
  newAccountCreatedRows: RowDetailsRepresentation[];
}

export interface RowDetailsRepresentation {
  email: string;
  code: BulkBookingErrorCode;
  status: string;
}

export enum BulkBookingErrorCode {
  DUPLICATE_EMAIL = 'DUPLICATE_EMAIL',
  ACCOUNT_CREATION_FAILED = 'ACCOUNT_CREATION_FAILED',
  BOOKING_ALREADY_EXISTS = 'BOOKING_ALREADY_EXISTS',
  OTHER = 'OTHER',
}
