/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import withLoader from '../../../helpers/withloader';
import {
  BookingParticipantState,
  BookingState,
  FitnessState,
  IbookingsParticipantsList,
  IParticipantsFilter,
} from '../../../models/Booking/BookingParticipantsList';
import BookingParticipantService from '../../../api/BookingParticipantService';
import { Dialog } from 'primereact/dialog';
import BookingService from '../../../api/BookingService';
import { Document, Page, pdfjs } from 'react-pdf';
import moment from 'moment';
import { IFitnessAprovalDashboardFilterCriteria } from './IFitnessAprovalDashboardFilterCriteria';
import { Controller, useForm } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import { AutoCompleteCompleteMethodParams, ITrekLookupModel } from '../../../models';
import { Calendar } from 'primereact/calendar';
import TrekService from '../../../api/TrekService';
import { InputNumber } from 'primereact/inputnumber';
import AutoCompleteSearchBox from '../../common/AutoCompleteSearchBox';
import IconButton, { IconButtonVariant } from '../../common/IconButton';
import ParticipantActionDialog, { ParticipantActionType } from '../../common/ParticipantActionDialog';
import './FitnessApproval.scss';
import { AutoComplete } from 'primereact/autocomplete';
import { searchInItems } from '../../../helpers/utils';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DEFAULT_PARTICIPANT_STATES = [BookingParticipantState.PENDING_APPROVAL, BookingParticipantState.FITNESS_REJECTED];

const FitnessApproval: React.FC<RouteComponentProps> = () => {
  const [autoFilteredTrekValue, setAutoFilteredTrekValue] = useState<ITrekLookupModel[]>([]);
  const [approveParticipants, setapproveParticipants] = useState<IbookingsParticipantsList[]>();
  const [selectedapproveParticipants, setSelectedapproveParticipants] = useState<any>();
  const [loading, setLoading] = useState(true);
  const toast = useRef(null);
  const [displayFitnessImage, setDisplayFitnessImage] = useState(false);
  const [fitnessDocument, setFitnessDocument] = useState(undefined);
  const [numPages, setNumPages] = useState(null);
  const [participantsFilter, setParticipantsFilter] = useState<IParticipantsFilter>({
    bookingState: [BookingState.COMPLETED],
    participantStatesList: DEFAULT_PARTICIPANT_STATES,
  });
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
  });
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [selectedParticipant, setSelectedParticipant] = useState<IbookingsParticipantsList | undefined>(undefined);
  const [actionType, setActionType] = useState<ParticipantActionType | undefined>(undefined);

  const resetState = () => {
    setLazyParams({ first: 0, rows: 10, page: 0 });
  };
  const { handleSubmit, reset, control, formState } = useForm<IFitnessAprovalDashboardFilterCriteria>({});
  const [autoTrekValueData, setAutoTrekValueData] = useState<ITrekLookupModel[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  let genderOptionDropdown: any = [];
  genderOptionDropdown.push({ name: 'Male', code: 'male' });
  genderOptionDropdown.push({ name: 'Female', code: 'female' });

  let fitnessStatusDropdownOptions: any = [];
  fitnessStatusDropdownOptions.push({ name: 'Pending Approval', code: FitnessState.PENDING_APPROVAL });
  fitnessStatusDropdownOptions.push({ name: 'Rejected', code: FitnessState.FITNESS_REJECTED });
  fitnessStatusDropdownOptions.push({ name: 'Approved', code: FitnessState.APPROVED });
  fitnessStatusDropdownOptions.push({ name: 'Document available', code: FitnessState.DOCUMENT_AVAILABLE });
  fitnessStatusDropdownOptions.push({ name: 'Document not available', code: FitnessState.DOCUMENT_NOT_AVAILABLE });

  useEffect(() => {
    loadAllParticipantsForApproval(lazyParams.page, lazyParams.rows, participantsFilter);
    const trekService = TrekService;
    trekService.getByLookupFormat().then((trekData) => {
      setAutoTrekValueData(trekData);
    });
  }, []);

  const UserNotFoundException = (participant: IbookingsParticipantsList) => {
    return { error: `No User Found for Participant ${participant.id}`, participant };
  };

  const loadAllParticipantsForApproval = (page: number, rows: number, participantsFilter?: IParticipantsFilter) => {
    setLoading(true);
    BookingParticipantService.getParticipantForApprovalDashboard(page + 1, rows, participantsFilter).then(
      (data: any) => {
        (data.data as IbookingsParticipantsList[]).forEach((part) => {
          try {
            if (!part.userDetailsForDisplay) throw UserNotFoundException(part);
            part.firstName = part.userDetailsForDisplay.firstName;
            part.userReferenceId = part.userDetailsForDisplay.userReferenceId;
            part.lastName = part.userDetailsForDisplay.lastName;
            part.height = part.userDetailsForDisplay.height;
            part.weight = part.userDetailsForDisplay.weight;
            part.bmi = part.userDetailsForDisplay.bmi;
            part.phone = part.userDetailsForDisplay.phone;
            part.email = part.userDetailsForDisplay.email;
            part.dob = part.userDetailsForDisplay.dob;
            part.gender = part.userDetailsForDisplay.gender;
          } catch (e) {
            console.log(e);
          }
        });
        setTotalRecords(Number(data.headers['x-number-of-participants-found']));
        setapproveParticipants(data.data);
        setLoading(false);
      },
    );
  };

  // @ts-ignore
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const approveParticipantsTableHeader = (
    <div className="table-header">
      <h4 className="p-m-0">
        <b>Check and Approve Participants</b>
      </h4>
    </div>
  );

  const approveParticipant = (data: any) => {
    setLoading(true);
    BookingParticipantService.approveBooking(data.id)
      .then(() => {
        loadAllParticipantsForApproval(lazyParams.page, lazyParams.rows, participantsFilter);
        const tst: any = toast?.current;
        tst.show({ severity: 'success', summary: 'Participant Approved Successfully', detail: '' });
      })
      .catch((res) => {
        const tst: any = toast?.current;
        if (res.response.data.message)
          tst.show({ severity: 'error', summary: `${res.response.data.message}`, detail: '' });
        else
          tst.show({
            severity: 'error',
            summary: 'Participant approval failed.',
            detail: '',
          });
      });
  };

  const bodyTemplate = (data: any, props: any) => {
    return (
      <>
        <span className="p-column-title">{data[props.field]}</span>
      </>
    );
  };

  const dateTemplate = (data: any, props: any) => {
    return (
      <>
        <span className="p-column-title">{moment(data[props.field]).format('DD-MMM-YY')}</span>
      </>
    );
  };

  const statusbodyTemplate = (data: any, props: any) => {
    const bookingParticipantState = data[props.field];
    switch (bookingParticipantState) {
      case FitnessState.APPROVED:
        return <span className="status-success">Approved</span>;
      case FitnessState.PENDING_APPROVAL:
        return <span className="status-dark">Pending</span>;
      case FitnessState.FITNESS_REJECTED:
        return <span className="status-danger">Rejected</span>;
      default:
        return <span className="status-warning">{bookingParticipantState}</span>;
    }
  };

  const fitnessImageTemplate = (data: any, props: any) => {
    if (data.bookingParticipantState == 'CANCELLED' || !data.fitnessDocumentExists) {
      return <div>&nbsp;</div>;
    } else {
      return (
        <div>
          <i
            className="pi pi-eye icon-success-color"
            color="secondary"
            onClick={() => showFitnessCertificate(data.id)}
          ></i>
        </div>
      );
    }
  };

  const actionTemplate = (data: any) => {
    return (
      <>
        <div className="d-flex align-items-center gap-2">
          <div className="p-mx-1">
            <Link to={`/bookings/view/${data.bookingId}`} target="_blank">
              <i className="pi pi-eye icon-success-color" color="secondary"></i>
            </Link>{' '}
          </div>
          {data.bookingParticipantState !== BookingParticipantState.CANCELLED &&
            data.bookingParticipantState !== BookingParticipantState.APPROVED && (
              <div className="p-mx-1">
                <i
                  className="pi pi-bell icon-info-color cursor-pointer"
                  color="secondary"
                  onClick={() => {
                    setSelectedParticipant(data);
                    setActionType(ParticipantActionType.Reminder);
                    setDisplayModal(true);
                  }}
                ></i>
              </div>
            )}
          {data.bookingParticipantState !== BookingParticipantState.CANCELLED &&
            data.bookingParticipantState !== BookingParticipantState.APPROVED && (
              <div className="p-mx-1">
                <IconButton
                  defaultIcon="pi pi-check"
                  onClick={() => approveParticipant(data)}
                  loading={loading}
                  buttonWithLoader={true}
                />
              </div>
            )}

          {data.bookingParticipantState !== BookingParticipantState.CANCELLED &&
            data.bookingParticipantState !== BookingParticipantState.FITNESS_REJECTED && (
              <div className="p-mx-1">
                <IconButton
                  defaultIcon="pi pi-times"
                  onClick={() => {
                    setSelectedParticipant(data);
                    setActionType(ParticipantActionType.Disapprove);
                    setDisplayModal(true);
                  }}
                  loading={false}
                  variant={IconButtonVariant.DANGER}
                />
              </div>
            )}
        </div>
      </>
    );
  };

  const showFitnessCertificate = (participantId: number) => {
    setFitnessDocument(undefined);
    BookingService.getParticipantDocument(participantId, 'FITNESS_APPROVAL')
      .then((data) => {
        setDisplayFitnessImage(true);
        // @ts-ignore
        setFitnessDocument(window.URL.createObjectURL(data));
      })
      .catch((error) => {
        setDisplayFitnessImage(true);
        console.log(error);
      });
  };

  const onSubmit = (data: any) => {
    let localParticipantsFilter: IParticipantsFilter = participantsFilter;

    if (data.trek != null) localParticipantsFilter.trekIds = data.trek.map((trek: ITrekLookupModel) => trek.trekId);

    if (data.selectedUser !== undefined && data.selectedUser.email) {
      localParticipantsFilter.email = [data.selectedUser.email];
    }

    if (data.batchStartDateFrom != null) {
      localParticipantsFilter.batchStartDateFrom = moment(data.batchStartDateFrom).format('yyyy-MM-DD');
    }
    if (data.batchStartDateTo != null) {
      localParticipantsFilter.batchStartDateTo = moment(data.batchStartDateTo).format('yyyy-MM-DD');
    }

    if (data.bookedOnFrom != null) {
      localParticipantsFilter.bookedOnFrom = moment(data.bookedOnFrom).format('yyyy-MM-DD');
    }
    if (data.bookedOnTo != null) {
      localParticipantsFilter.bookedOnTo = moment(data.bookedOnTo).format('yyyy-MM-DD');
    }

    if (data.gender != null) localParticipantsFilter.gender = data.gender;

    if (data.fitnessState != null) {
      if (
        data.fitnessState === FitnessState.APPROVED ||
        data.fitnessState === FitnessState.PENDING_APPROVAL ||
        data.fitnessState === FitnessState.FITNESS_REJECTED
      ) {
        localParticipantsFilter.participantState = data.fitnessState;
        localParticipantsFilter.participantStatesList = undefined;
      } else {
        localParticipantsFilter.participantState = undefined;
        localParticipantsFilter.participantStatesList = DEFAULT_PARTICIPANT_STATES;
        if (data.fitnessState === FitnessState.DOCUMENT_AVAILABLE) {
          localParticipantsFilter.fitnessDocumentsExists = true;
        }
        if (data.fitnessState === FitnessState.DOCUMENT_NOT_AVAILABLE) {
          localParticipantsFilter.fitnessDocumentsExists = false;
        }
      }
    }
    if (data.bmiFrom != null) localParticipantsFilter.bmiFrom = data.bmiFrom;

    if (data.bmiTo != null) localParticipantsFilter.bmiTo = data.bmiTo;

    if (data.ageFrom != null) localParticipantsFilter.participantAgeFrom = data.ageFrom;

    if (data.ageTo != null) localParticipantsFilter.participantAgeTo = data.ageTo;

    setParticipantsFilter(localParticipantsFilter);
    resetState();
    loadAllParticipantsForApproval(lazyParams.page, lazyParams.rows, localParticipantsFilter);
  };

  const userItemTemplate = (item: any) => {
    return (
      <>
        <span className="test">
          {item.firstName} {item.lastName}{' '}
        </span>
        <span>({item.email})</span>
      </>
    );
  };

  const onPage = (event: any) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
    loadAllParticipantsForApproval(_lazyParams.page, _lazyParams.rows, participantsFilter);
  };

  const handleSearch = (
    event: AutoCompleteCompleteMethodParams,
    setterFunction: any,
    key: string,
    itemsToBeSearched: any[],
  ) => {
    if (!event.query.trim().length) {
      setterFunction([...(itemsToBeSearched || [])]);
    } else {
      const searchedItems = searchInItems(event.query.toLowerCase(), key, itemsToBeSearched);
      setterFunction(searchedItems.map((item) => item.item));
    }
  };

  return (
    <>
      <div className="table-demo">
        <Toast ref={toast} />

        <div className="card">
          <form onSubmit={handleSubmit(onSubmit)} onReset={() => reset}>
            <div className="card">
              <div className="p-grid">
                <div className="p-col-12 p-md-6 p-lg-3">
                  <div>
                    <div className="p-mb-2">
                      <label htmlFor="name">Trek Name:</label>
                    </div>
                    <div>
                      <Controller
                        name="trek"
                        control={control}
                        render={({ onChange, value }) => (
                          <AutoComplete
                            placeholder="Select Trek"
                            dropdown
                            multiple
                            value={value}
                            onChange={(e) => {
                              onChange(e.value);
                            }}
                            suggestions={autoFilteredTrekValue}
                            completeMethod={(e) => handleSearch(e, setAutoFilteredTrekValue, "name", autoTrekValueData)}
                            field="name"
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-3">
                  <div>
                    <div className="p-mb-2">
                      <label htmlFor="email">Participant Email:</label>
                    </div>
                    <div>
                      <Controller
                        name="selectedUser"
                        control={control}
                        render={({ onChange, value }) => (
                          <AutoCompleteSearchBox
                            value={value}
                            onChange={onChange}
                            displayField="displayName"
                            searchServiceType="CUSTOMER"
                            itemTemplate={userItemTemplate}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-3">
                  <div>
                    <div className="p-mb-2">
                      <label htmlFor="name">Batch start date from:</label>
                    </div>
                    <div>
                      <Controller
                        name="batchStartDateFrom"
                        control={control}
                        render={({ onChange, value }) => (
                          <Calendar
                            dateFormat="dd/mm/yy"
                            monthNavigator
                            yearNavigator
                            yearRange="2010:2030"
                            value={value}
                            onChange={(e) => onChange(e.value)}
                          ></Calendar>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-3">
                  <div>
                    <div className="p-mb-2">
                      <label htmlFor="name">Batch start date to:</label>
                    </div>
                    <div>
                      <Controller
                        name="batchStartDateTo"
                        control={control}
                        render={({ onChange, value }) => (
                          <Calendar
                            dateFormat="dd/mm/yy"
                            monthNavigator
                            yearNavigator
                            yearRange="2010:2030"
                            value={value}
                            onChange={(e) => onChange(e.value)}
                          ></Calendar>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-3">
                  <div>
                    <div className="p-mb-2">
                      <label htmlFor="name">Booked on from:</label>
                    </div>
                    <div>
                      <Controller
                        name="bookedOnFrom"
                        control={control}
                        render={({ onChange, value }) => (
                          <Calendar
                            dateFormat="dd/mm/yy"
                            value={value}
                            monthNavigator
                            yearNavigator
                            yearRange="2010:2030"
                            onChange={(e) => onChange(e.value)}
                          ></Calendar>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-3">
                  <div>
                    <div className="p-mb-2">
                      <label htmlFor="name">Booked on to:</label>
                    </div>
                    <div>
                      <Controller
                        name="bookedOnTo"
                        control={control}
                        render={({ onChange, value }) => (
                          <Calendar
                            dateFormat="dd/mm/yy"
                            value={value}
                            monthNavigator
                            yearNavigator
                            yearRange="2010:2030"
                            onChange={(e) => onChange(e.value)}
                          ></Calendar>
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="p-col-12 p-md-6 p-lg-3">
                  <div>
                    <div className="p-mb-2">
                      <label htmlFor="name">Gender:</label>
                    </div>
                    <div>
                      <Controller
                        name="gender"
                        control={control}
                        render={({ onChange, value }) => (
                          <Dropdown
                            optionLabel="name"
                            optionValue="code"
                            value={value}
                            options={genderOptionDropdown}
                            onChange={(e) => {
                              onChange(e.value);
                            }}
                            placeholder="Select a Gender"
                            className="g-drp"
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-3">
                  <div>
                    <div className="p-mb-2">
                      <label htmlFor="email">Fitness status:</label>
                    </div>
                    <div>
                      <Controller
                        name="fitnessState"
                        control={control}
                        render={({ onChange, value }) => (
                          <Dropdown
                            optionLabel="name"
                            optionValue="code"
                            value={value}
                            options={fitnessStatusDropdownOptions}
                            onChange={(e) => {
                              onChange(e.value);
                            }}
                            placeholder="Select a fitness status"
                            filter
                            filterBy="name"
                            className="g-drp"
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-3">
                  <div>
                    <div className="p-mb-2">
                      <label>BMI From:</label>
                    </div>
                    <div>
                      <Controller
                        name="bmiFrom"
                        control={control}
                        render={({ onChange, value }) => (
                          <InputNumber
                            value={value}
                            onChange={(e) => {
                              onChange(e.value);
                            }}
                            placeholder="Enter bmi from value."
                            className="g-drp"
                            mode="decimal"
                            minFractionDigits={2}
                            maxFractionDigits={2}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-3">
                  <div>
                    <div className="p-mb-2">
                      <label>BMI To:</label>
                    </div>
                    <div>
                      <Controller
                        name="bmiTo"
                        control={control}
                        render={({ onChange, value }) => (
                          <InputNumber
                            value={value}
                            onChange={(e) => {
                              onChange(e.value);
                            }}
                            placeholder="Enter bmi from value."
                            className="g-drp"
                            mode="decimal"
                            minFractionDigits={2}
                            maxFractionDigits={2}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-3">
                  <div>
                    <div className="p-mb-2">
                      <label>Age From:</label>
                    </div>
                    <div>
                      <Controller
                        name="ageFrom"
                        control={control}
                        render={({ onChange, value }) => (
                          <InputNumber
                            value={value}
                            onChange={(e) => {
                              onChange(e.value);
                            }}
                            placeholder="Enter age from value."
                            className="g-drp"
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-3">
                  <div>
                    <div className="p-mb-2">
                      <label>Age To:</label>
                    </div>
                    <div>
                      <Controller
                        name="ageTo"
                        control={control}
                        render={({ onChange, value }) => (
                          <InputNumber
                            value={value}
                            onChange={(e) => {
                              onChange(e.value);
                            }}
                            placeholder="Enter bmi from value."
                            className="g-drp"
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div className="flex-grow-1"></div>
              <div>
                <Button
                  type="submit"
                  label="Submit"
                  icon="pi pi-filter"
                  className="p-mx-2 p-button-lg p-button-secondary"
                  disabled={formState.isSubmitting}
                >
                  {formState.isSubmitting && <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>}
                </Button>
              </div>
            </div>
          </form>
          <DataTable
            value={approveParticipants}
            lazy={true}
            paginator
            scrollable
            style={{ width: '100%' }}
            first={lazyParams.first}
            rowsPerPageOptions={[10, 20, 50]}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            onPage={onPage}
            dataKey="id"
            rowHover
            selection={selectedapproveParticipants}
            onSelectionChange={(e: any) => setSelectedapproveParticipants(e.value)}
            emptyMessage="No record(s) found."
            loading={loading}
            header={approveParticipantsTableHeader}
          >
            <Column
              field="firstName"
              header="First Name"
              headerStyle={{ width: '100px' }}
              sortable
              body={bodyTemplate}
            ></Column>
            <Column
              field="lastName"
              header="Last Name"
              headerStyle={{ width: '100px' }}
              sortable
              body={bodyTemplate}
            ></Column>
            <Column field="email" header="Email" headerStyle={{ width: '125px' }} sortable body={bodyTemplate}></Column>
            <Column
              field="phone"
              header="Phone Number"
              headerStyle={{ width: '100px' }}
              sortable
              body={bodyTemplate}
            ></Column>
            <Column
              field="trekName"
              header="Trek Name"
              headerStyle={{ width: '125px' }}
              sortable
              body={bodyTemplate}
            ></Column>
            <Column
              field="batchStartDate"
              header="Trek Date"
              headerStyle={{ width: '80px' }}
              sortable
              body={dateTemplate}
            ></Column>
            <Column field="bmi" header="BMI" headerStyle={{ width: '40px' }} sortable body={bodyTemplate}></Column>
            <Column field="dob" header="DOB" headerStyle={{ width: '60px' }} sortable body={dateTemplate}></Column>
            <Column
              field="gender"
              header="Gender"
              headerStyle={{ width: '60px' }}
              sortable
              body={bodyTemplate}
            ></Column>
            <Column
              field="bookingParticipantState"
              header="Fitness Status"
              headerStyle={{ width: '90px' }}
              sortable
              body={statusbodyTemplate}
            ></Column>
            <Column
              header="Fitness Proof"
              headerStyle={{ width: '90px' }}
              sortable
              body={fitnessImageTemplate}
            ></Column>
            <Column
              headerStyle={{ width: '140px', textAlign: 'center' }}
              bodyStyle={{ textAlign: 'center', overflow: 'visible', justifyContent: 'center' }}
              body={actionTemplate}
              header="Action"
            ></Column>
          </DataTable>
        </div>
      </div>

      <Dialog
        header="Fitness Image"
        visible={displayFitnessImage}
        style={{ width: '50vw' }}
        onHide={() => setDisplayFitnessImage(false)}
      >
        {fitnessDocument && (
          <Document file={fitnessDocument} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        )}
      </Dialog>
      {actionType && (
        <ParticipantActionDialog
          actionType={actionType}
          displayModal={displayModal}
          onHide={() => setDisplayModal(false)}
          isSubmitting={loading}
          selectedParticipantId={selectedParticipant?.id}
          postCallback={() => loadAllParticipantsForApproval(lazyParams.page, lazyParams.rows, participantsFilter)}
          setLoader={setLoading}
        />
      )}
    </>
  );
};

export default withLoader(FitnessApproval);
