import moment from 'moment';
import Fuse from "fuse.js";
import { Toast } from 'primereact/toast';
import * as xlsx from 'xlsx';
import { saveAs } from 'file-saver';

/* eslint-disable */
export const checkForZeroOrNull = (value: number | string): boolean => {
  if (value === 0 || value === null) return true;
  return false;
};

export const checkForZeroOrNullOrUndefined = (value: number | string | undefined): boolean => {
  if (typeof value === 'undefined' || checkForZeroOrNull(value)) return true;
  return false;
};

export const checkForAccurateDataInArray = (valueArray: Array<number | string | undefined>): boolean => {
  for (const value in valueArray) {
    if (checkForZeroOrNullOrUndefined(value)) return true;
  }
  return false;
};

export const compareDates = (date1: Date, date2: Date, allowOverlap: boolean = false): boolean => {
  const date1Instance = new Date(date1);
  const date2Instance = new Date(date2);
  if (allowOverlap) {
    return date1Instance.valueOf() >= date2Instance.valueOf();
  }
  return date1Instance.valueOf() > date2Instance.valueOf();
};

export const getDataWithDebounce = <T extends (...args: any[]) => any>(
  func: T,
  delay: number,
): ((...args: Parameters<T>) => void) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return (...args: Parameters<T>): void => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func.apply(null, args);
    }, delay);
  };
};

/**
 * a helper that creates an anchor element, sets the download attribute and dispatches an event to export data into a file.
 * @param data
 * @param fileNamePrefix
 * @param extension
 */
export function exportToFile(data: Blob, fileNamePrefix: string, extension: 'xlsx' | 'pdf' = 'xlsx') {
  const fileUrl = window.URL.createObjectURL(data);
  const tempLink = document.createElement('a');
  tempLink.href = fileUrl;
  tempLink.setAttribute('download', `${fileNamePrefix}_${moment().format('DD_MM_YYYY-h_mm_a')}.${extension}`);
  tempLink.click();
  tempLink.remove();
  window.URL.revokeObjectURL(fileUrl);
}

/**
 * a helper that calculates age from the date of birth
 * @param birthday
 * @returns
 */
export function calculateAge(birthday: Date) {
  const today = new Date();
  let age = today.getFullYear() - birthday.getFullYear();
  if (
    today.getMonth() < birthday.getMonth() ||
    (today.getMonth() === birthday.getMonth() && today.getDate() < birthday.getDate())
  ) {
    age -= 1;
  }
  return age;
}

export function copyToClipboard(text: string, toastRef?: Toast | null) {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      toastRef?.show({
        severity: 'success',
        summary: 'Copied to clipboard',
      });
    })
    .catch((err) => {
      toastRef?.show({
        severity: 'error',
        summary: 'Failed copying to clipboard',
      });
      throw new Error(err);
    });
}

export function exportToExcel(data: any, fileName: string) {
  const worksheet = xlsx.utils.json_to_sheet(data);

  const workbook = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(workbook, worksheet, 'Transport');

  const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });

  const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

  saveAs(blob, fileName);
}

/**
 * Serializes an object into a URL query string.
 * Filters out fields with undefined or null values.
 * Converts arrays into multiple query parameters with the same key.
 * 
 * @param {Object} params - The object to be serialized.
 * @returns {string} - The serialized query string.
 */
export const serializeParams = (params: any) => {
  const searchParams = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (value) {
      if (Array.isArray(value)) {
        value.forEach((val: any) => searchParams.append(key, val));
      } else {
        searchParams.append(key, value);
      }
    }
  });
  return searchParams.toString();
};

/**
 * Searches for a specific text within an array of items based on a given key.
 * Uses the Fuse.js library for fuzzy searching.
 * 
 * @param {string} searchText - The text to search for.
 * @param {string} key - The key in the items to search within.
 * @param {Array} itemsToBeSearched - The array of items to search through.
 * @returns {Array} - An array of items that match the search criteria.
 */
export const searchInItems = (searchText: string, key: string, itemsToBeSearched: any[]) => {
  const fuse = new Fuse(itemsToBeSearched, {
    threshold: 0.2,
    keys: [key],
    ignoreLocation: false,
    useExtendedSearch: false,
  });
  return fuse.search(searchText);
};
